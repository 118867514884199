import React from "react"
import ReactMarkdown from "react-markdown/with-html"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Display,
  NewsWrapper,
  Markdown,
  NewsImg,
  NewsText,
  Wrapper,
  Title,
  Body,
} from "../styles"

const News = ({ data }) => {
  const newss = data.allStrapiNewss.edges.map(edge => edge.node)

  return (
    <Layout>
      <SEO title="News" />
      <Display center>News</Display>

      {newss.map((news, i) => (
        <div key={i}>
          <NewsWrapper row alignCenter justifyEvenly>
            <NewsText>
              <Wrapper row wrap justifyBetween>
                <Title>{news.title}</Title>
                <Body style={{ opacity: "0.7", marginRight: "15px" }}>
                  {news.date}
                </Body>
              </Wrapper>
              <Markdown>
                <ReactMarkdown source={news.text} escapeHtml={false} />
              </Markdown>
            </NewsText>
            {news.picture && (
              <NewsImg>
                <Img
                  style={{ borderRadius: "5px" }}
                  fluid={news.picture.childImageSharp.fluid}
                />
              </NewsImg>
            )}
          </NewsWrapper>
          <hr style={{ width: "100%", opacity: "0.5" }} />
        </div>
      ))}
    </Layout>
  )
}

export default News

export const newsQuery = graphql`
  query {
    allStrapiNewss(sort: { order: DESC, fields: date }) {
      edges {
        node {
          text
          date
          title
          picture {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
